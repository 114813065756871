import { Card, Radio, Select, Spin, Tabs, message } from 'antd';
import { useEffect, useRef, useState } from "react";
import { config } from "../config";
import ApiHelper from "../util/ApiHelper";
import { ImageSelectList } from "./ImageSelectList";
import { InlineEdit } from './InplaceEditable';
import { ProductQuantities } from "./ProductQuantities";
import { Specifications } from "./Specifications";

const ExtraFields = (props) => {
    const [status, setStatus] = useState(props.data.status)
    const [generalLedger, setGeneralLedger] = useState(props.data.generalLedger)
    const [category, setCategory] = useState(props.data.category)
    const [additionalCategories, setAdditionalCategories] = useState(props.data.additionalCategories)
    const [brand, setBrand] = useState(props.data.brand)

    const handleFieldChange = (field, value) => {
        props.onChange(field, value)
    }

    useEffect(() => {
        setStatus(props.data.status)
        setGeneralLedger(props.data.generalLedger)
        setCategory(props.data.category)
        setAdditionalCategories(props.data.additionalCategories)
    }, [props.data])

    useEffect(() => {
        props.onChange({
            status, generalLedger, category, additionalCategories, brand
        })
    }, [props, status, generalLedger, category, additionalCategories, brand])
    return (
        <>
            <Card title="Product Status" className={props.updateStatus !=''?'product-updated':''}>
                <Radio.Group options={config.productStatusOptions} optionType="button" value={status} buttonStyle="solid" onChange={e => setStatus(e.target.value)} />
            </Card>
            <Card>
                <div className="fields">
                    <label>General Ledger</label>
                    <Select
                        placeholder="General Ledger"
                        optionFilterProp="label"
                        onChange={setGeneralLedger}
                        value={generalLedger}
                        options={props.generalLedgers}
                    />
                </div>
                <div className="fields">
                    <label>Select Category</label>
                    <Select
                        showSearch
                        placeholder="Select Category"
                        optionFilterProp="label"
                        onChange={setCategory}
                        value={category}
                        options={props.categories}
                    />
                </div>
                <div className="fields">
                    <label>Additional Categories</label>
                    <Select
                        showSearch
                        placeholder="Select Additional Categories"
                        optionFilterProp="label"
                        onChange={setAdditionalCategories}
                        value={additionalCategories}
                        options={props.categories}
                        mode="multiple"
                    />
                </div>
                <div className="fields">
                    <label>Brand</label>
                    <Select
                        showSearch
                        placeholder="Select Brand"
                        optionFilterProp="label"
                        onChange={setBrand}
                        value={brand}
                        options={props.cmsBrands}
                        fieldNames={{
                            value: "id",
                            label: "name"
                        }}
                        filterOption={(input, option) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }

                    />
                </div>
            </Card>
        </>
    )
}

export const ProductDetails = ({ data, onUpdate, supplier }) => {
    const [generalLedgers, setGeneralLedgers] = useState([])
    const [cmsBrands, setCmsBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [productMapping, setProductMapping] = useState({})
    const [priceSettings, setPriceSettings] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const productData = useRef([])

    const handleFieldChange = (field, value, section = null) => {
        let _currentData = productData.current
        if (section) {
            if (typeof _currentData[section] === 'undefined') {
                _currentData[section] = {}
            }
            _currentData[section][field] = value
        }
        else {
            _currentData[field] = value
        }
        productData.current = _currentData
    }

    const saveProductMapping = (event) => {
        event.preventDefault()
        ApiHelper.saveProductMapping(supplier, data.code, { ...productData.current })
            .then(data => {
                if (data.status) {
                    message.success(data.message)
                    setProductMapping(data.data)
                    onUpdate();
                }
                else {
                    message.error(data.message)
                }
            })
    }

    const generateProductPreview = (event) => {
        event.preventDefault()
        ApiHelper.generateProductPreview(supplier, data.code, { ...productData.current })
            .then(data => {
                if (data) {
                    message.success('Preview generated successfully')
                    setProductMapping(data)
                    onUpdate();
                }
                else {
                    message.error('Unable to generate preview')
                }
            })
    }

    const forceProductPush = (event) => {
        event.preventDefault()
        ApiHelper.forceProductPush(supplier, data.code, { ...productData.current })
            .then(data => {
                if (data) {
                    message.success('Product Pushed to CMS')
                    setProductMapping(data)
                    onUpdate();
                }
                else {
                    message.error('Got an error')
                }
            })
    }

    useEffect(() => {
        Promise.all([
            ApiHelper.getCMSGeneralLedgers()
                .then(result => setGeneralLedgers(result)),
            ApiHelper.getCMSCategories()
                .then(result => {
                    setCategories(result)
                }),
            ApiHelper.getProductMapping(supplier, data.code)
                .then(result => {
                    setProductMapping(result)
                    productData.current = result
                }),
            ApiHelper.getPriceSettings(supplier)
                .then(result => {
                    setPriceSettings(result)
                }),
            ApiHelper.getCmsBrands()
                .then(result => setCmsBrands(result)),
        ]).then(res => setIsLoading(false))
    }, [])

    return (
        isLoading ? <Spin /> : <div className={`products-details ${data?.offline && 'products-details--offline'}`}>
            <header className="products-details__header">
                <h1>{data.name.nl}</h1>
            </header>
            <div className="products-details__wrapper">

                <div className="column sidebar">
                    <div className="basic">
                        <div className="description-item">
                            <h3 className="description-label">Supplier Model</h3>
                            <h3 className="description-content">{data.code}</h3>
                        </div>
                        <Tabs defaultActiveKey="1">
                            {Object.keys(config.baseLanguages).map((lang) => (
                                <Tabs.TabPane tab={lang.toUpperCase()} key={lang}>
                                    <InlineEdit value={productData.current?.title?.[lang] ?? data.name[lang]} onChange={v => productData.current.title = { ...productData.current.title ?? {}, [lang]: v }} original={data.name[lang]} />
                                    <InlineEdit value={productData.current?.description?.[lang] ?? data.description[lang]} onChange={v => productData.current.description = { ...productData.current.description ?? {}, [lang]: v }} original={data.description[lang]} multiline />
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </div>
                    <div className="pricing">
                        <Card title="Product Quantities" bodyStyle={{ padding: 0 }}>
                            {data.items[0].prices && <ProductQuantities
                                priceBars={data.items[0].prices}
                                quantities={productMapping.quantities}
                                maxPriceQuantity={productMapping.maxPriceQuantity}
                                priceSettings={priceSettings}
                                onChange={quantities => handleFieldChange('quantities', quantities)}
                                onMaxPriceQuantityChange={quantity => handleFieldChange('maxPriceQuantity', quantity)}
                            />}
                        </Card>
                        <div className="extra-fields">
                            <ExtraFields onChange={(value) => handleFieldChange('extra', value)} data={productMapping.extra || {}} updateStatus={data?.updated_by_feed || ''} {...{ generalLedgers, categories, subCategories, cmsBrands }} />
                        </div>
                        {productMapping.previewUrls && <div className="preview-buttons-container box">
                            <div className="title">Previews</div>
                            <div className="preview-buttons">
                                {Object.keys(productMapping.previewUrls).map(lang => {
                                    return (
                                        <a key={lang} className="button" href={productMapping.previewUrls[lang]} target="_blank">{lang}</a>
                                    )
                                })}
                            </div>
                            <i>(Use "Save and Generate Preview" to regenerate preview)</i>
                        </div>}
                        <div className="actions">
                            <button className="primary" onClick={saveProductMapping}>Save</button>
                            <button onClick={forceProductPush}>Save &amp; Push</button>
                            <button onClick={generateProductPreview}>Save &amp; Generate Preview</button>
                        </div>
                    </div>
                </div>
                <main className="column main-content">
                    <div className="specifications-list">
                        {Object.keys(data.specs).map(spec => {
                            return (
                                data.specs[spec].length > 0 ?
                                    <Specifications
                                        key={spec}
                                        supplier={data.supplier}
                                        specificationList={data.specs[spec]}
                                        specificationType={spec}
                                        selectedSpecifications={typeof productMapping.specifications !== 'undefined' ? productMapping.specifications[spec] : []}
                                        onSelectionChange={(selections, type) => handleFieldChange(type, selections, 'specifications')}
                                        onUpdate={onUpdate}
                                        product={data} />
                                    : ''
                            )
                        })}
                    </div>
                    <div className="images">
                        <ImageSelectList updatedImages={data.productUpdatedImages} images={data.images} selectedImages={productMapping.selectedImages || []} onChange={selections => handleFieldChange('selectedImages', selections)} />
                    </div>
                </main>
            </div>
        </div>
    )
} 